import React, { useEffect, useState } from "react";
import rn_img from "../../../assets/images/react_icon.webp";
import js_img from "../../../assets/images/js_icon.webp";
import flu_img from "../../../assets/images/flutter_icon.webp";
import csharp_img from "../../../assets/images/csharp_icon.webp";
import tailwind_img from "../../../assets/images/tailwindcss.webp";
import android_img from "../../../assets/images/android.webp";
import jira_img from "../../../assets/images/jira.webp";
import post_img from "../../../assets/images/postman.webp";
import git_img from "../../../assets/images/git_logo.webp";
import jen_img from "../../../assets/images/jenkins.webp";
import svn_img from "../../../assets/images/svn.webp";
import "./skills.css";

function Skills() {
  const [dev_selected, setSelectedSkill] = useState(true);

  function changeState() {
    setSelectedSkill(!dev_selected);
  }

  return (
    <section className="skills">
      <p className="font-bold text-2xl md:text-3xl">
        What I've used so far
      </p>
      <div className="switch">
        <p onClick={changeState}>Deveopment</p>
        <p onClick={changeState}>Tasks</p>
        <div
          className="slider"
          style={{
            gridColumnStart: dev_selected ? 1 : 2,
            borderRadius: dev_selected ? "30px 0 0 30px" : "0 30px 30px 0",
          }}
        ></div>
      </div>
      {dev_selected ? (
        <div
          className="skills-swiper"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="0"
        >
          <div className="skill">
            <img alt="react native" src={rn_img} />
            <p>React</p>
          </div>
          <div className="skill">
            <img alt="javascript" id="js-img" src={js_img} />
            <p>Javascript</p>
          </div>
          <div className="skill">
            <img alt="flutter" src={flu_img} />
            <p>Flutter</p>
          </div>
          <div className="skill">
            <img alt="csharp" style={{ scale: "1.7" }} src={csharp_img} />
            <p>C#</p>
          </div>
          <div className="skill">
            <img alt="tailwind" src={tailwind_img}/>
            <p>Tailwind CSS</p>
          </div>
          <div className="skill">
            <img alt="android" src={android_img} />
            <p>Android Studio</p>
          </div>
        </div>
      ) : (
        <div
          className="skills-swiper"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="0"
        >
          <div className="skill">
            <img alt="git" className="task-skill" src={git_img} />
            <p>Git</p>
          </div>
          <div className="skill">
            <img alt="jira" className="task-skill" id="js-img" src={jira_img} />
            <p>Jira</p>
          </div>
          <div className="skill">
            <img alt="postman" className="task-skill" src={post_img} />
            <p>Postman</p>
          </div>
          <div className="skill">
            <img alt="svn" className="task-skill" src={svn_img} />
            <p>Tortoise SVN</p>
          </div>
          <div className="skill">
            <img alt="jenkins" className="task-skill" src={jen_img} />
            <p>Jenkins</p>
          </div>
        </div>
      )}
    </section>
  );
}

export default Skills;
