import Projects from "./projects/projects";
import Hero from "../UI/Hero/hero";
import Banner from "./banner/banner";
import { useRef } from "react";

function Home() {
  const projectsRef = useRef(null);
  
  return (
    <>
      <Banner projectsRef={projectsRef} />
      <Projects ref={projectsRef} />
      <Hero />
    </>
  );
}

export default Home;
