import React from "react";

function Card({ title, description, url, logo }) {
  return (
    <div className="rounded-2xl relative shadow-xl w-full h-full justify-self-center group parent">
      <div className="absolute glow -inset-1 bg-gradient-to-br from-red-400 to-teal-600 rounded-lg blur group-hover:!opacity-90 opacity-25 transition duration-1000 group-hover:!duration-500"></div>
      <div className=" relative px-7 py-6 bg-mesh bg-cover ring-1 w-full h-full ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
        <a
          className="block rounded-xl w-full h-full bg-transparent p-4 sm:p-6 lg:p-8 hover:scale-95 duration-300 transition-all"
          href={url}
        >
          <div className="flex">
            <img className="w-10" alt={title} src={logo} />
          </div>
          <div className="mt-44">
            <h3 className="text-lg font-bold text-white sm:text-xl">{title}</h3>

            <p className="mt-2 text-sm text-white">{description}</p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Card;
