import React from "react";
import Button from "../Button/button";
import "./hero.css";

function Hero() {
  return (
    <section className="section-hero">
      <div className="box">
        <div className="hero-content">
          <p className="font-bold text-[1.7em] sm:text-[2em] md:text-[2.3em] lg:text-[2.5em]">Interested in my work?</p>
          <p className="my-[5vh]">
            If you’re looking for a full stack web and mobile developer that
            likes to expand knowledge every day, let’s talk.
          </p>
          <p
            style={{
              fontWeight: "bold",
              color: "var(--blue)",
              fontSize: "1.1em",
            }}
          >
            sekne50@gmail.com
          </p>
          <Button link={"#/contact"} content="Get in contact. Let's talk!" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
