import React from "react";
import "./footer.css";
import navIcon1 from "../../assets/images/nav-icon1.webp";
import navIcon2 from "../../assets/images/nav-icon3.webp";

function Footer() {
  return (
    <section className="footer">
      <div className="footer-div">
        <p style={{ marginBottom: "0" }}>(c) Copyright Jan Sekne 2024.</p>
        <div className="social-icons-footer">
          <a href="https://www.linkedin.com/in/jan-sekne/">
            <img src={navIcon1} alt="linkedin" />
          </a>
          <a href="https://www.instagram.com/jansekne/">
            <img src={navIcon2} alt="instagram" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Footer;
