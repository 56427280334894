import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home/home";
import Footer from "./components/footer/footer";
import Navbar from "./components/navbar/navbar";
import { Routes, Route } from "react-router-dom";
import AboutMe from "./components/aboutme/aboutme";
import Contact from "./components/contact/contact";
import "aos/dist/aos.css";
import AOS from "aos";
import PageNotFound from "./components/pageNotFound/PageNotFound";

function App() {
  AOS.init();

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
