import React from "react";

function Certificate({ title, url, link }) {
  return (
    <a className="hover:scale-125 transition-all duration-500" href={link}>
      <img src={url} alt={title} className="md:w-96 md:h-80" />
    </a>
  );
}

export default Certificate;
