import React from "react";
import Banner from "./banner/banner";
import Faq from "./faq/faq";
import Form from "./form/form";

function Contact() {
  return (
    <>
      <Banner />
      <Faq />
      <Form />
    </>
  );
}

export default Contact;
