import React, { useState } from "react";
import navIcon1 from "../../assets/images/nav-icon1.webp";
import navIcon2 from "../../assets/images/nav-icon3.webp";
import logo_img from "../../assets/images/logo.webp";
import { Link } from "react-router-dom";
import "./navbar.css";

function Navbar() {
  const [checkSelected, setCheckSelected] = useState(false);

  function handleCheckSelected() {
    setCheckSelected(!checkSelected);
  }

  return (
    <div className="flex w-full flex-row justify-between items-center h-[9vh]">
      <div className="logo flex">
        <Link className="logo-img link" to="">
          <img alt="Jan Sekne" src={logo_img} />
        </Link>
        <Link to="/aboutme" className="link">
          About Me
        </Link>
        <Link to="/contact" className="link">
          Contact
        </Link>
      </div>
      <div className="social-icon mr-20 flex ">
        <a href="https://www.linkedin.com/in/jan-sekne/">
          <img alt="linkedin" src={navIcon1} />
        </a>
        <a href="https://www.instagram.com/jansekne/">
          <img alt="instagram" src={navIcon2} />
        </a>
      </div>
      <nav role="navigation">
        <div id="menuToggle">
          <input
            id="checkboxMenu"
            checked={checkSelected}
            type="checkbox"
            onChange={handleCheckSelected}
          />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <Link className="link" to="/aboutme" onClick={handleCheckSelected}>
              <li>About Me</li>
            </Link>
            <Link className="link" to="/contact" onClick={handleCheckSelected}>
              <li>Contact</li>
            </Link>
            <a href="https://www.linkedin.com/in/jan-sekne/">
              <li>
                <img src={navIcon1} alt="linkedin" />
              </li>
            </a>
            <a href="https://www.instagram.com/jansekne/">
              <li>
                <img src={navIcon2} alt="instagram" />
              </li>
            </a>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
