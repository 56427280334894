import React from "react";
import "./banner.css";
import arrow_down from "../../../assets/images/arrow-down-solid.webp";
import iplus_img from "../../../assets/images/iplus_logo.webp";
import comtrade_img from "../../../assets/images/comtrade_logo.webp";
import tabsystems_img from "../../../assets/images/tabsystems_logo.webp";
import calima_img from "../../../assets/images/calima_logo.webp";
import agilcon_img from "../../../assets/images/agilcon.webp";

function Banner({ projectsRef }) {
  const handleClickScroll = (e) => {
    e.preventDefault();
    projectsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="content">
      <div className="hero items-center flex self-stretch flex-col relative mt-[10vh] sm:mt-[16vh] mb-[25vh]">
        <p
          className="name text-[1.8rem] sm:text-[2rem] text-center"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          Hey there, I’m Jan.
        </p>
        <p
          className="title text-[3em] sm:text-[3.8em]  text-center w-[90vw] my-9"
          data-aos="fade-up"
          data-aos-duration="1400"
        >
          Full stack Web & Mobile Developer
        </p>
        <p
          className="description text-sm w-[90vw] sm:w-[70vw] md:w-[40vw] text-center mb-16"
          data-aos="fade-up"
          data-aos-duration="1800"
        >
          I’m passionate software developer that helps business grow by crafting
          amazing web & mobile experiences. While I’m a student I still take
          interviews in search of a great team and projects.
        </p>
        <div data-aos="fade-up" data-aos-duration="1800">
          <div className="button-work !w-[155px]">
            <button onClick={handleClickScroll} className="flex">
              See my work
              <img alt="arrow down" src={arrow_down} />
            </button>
          </div>
        </div>
      </div>
      <div className="company-banner relative text-center">
        <p className="text-[1.1rem] text-black font-bold pt-5">
          Companies that I've worked for
        </p>
        <div className="text-center pb-7 justify-evenly grid grid-cols-2 gap-7 lg:grid-cols-4">
          <div className="img-wrapper self-center justify-self-center">
            <img
              alt="iplus"
              className="iplus-img object-contain w-32 sm:w-56"
              src={iplus_img}
            />
          </div>
          <div className="img-wrapper self-center justify-self-center">
            <img
              alt="comtrade"
              className="object-contain w-32 sm:w-56"
              src={comtrade_img}
            />
          </div>
          <div className="img-wrapper self-center justify-self-center">
            <img
              alt="agilcon"
              className="object-contain w-32 sm:w-56"
              src={agilcon_img}
            />
          </div>
          <div className="img-wrapper self-center justify-self-center">
            <img
              alt="tabsystems"
              className="tabsystems-img object-contain w-32 sm:w-56"
              src={tabsystems_img}
            />
          </div>
          <div className="img-wrapper self-center justify-self-center">
            <img
              alt="calima solutions"
              className="calima-img object-contain w-32 sm:w-56"
              src={calima_img}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
