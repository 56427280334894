import React from "react";
import guitar_profile from "../../../assets/images/guitar.webp";

function Introduction() {
  return (
    <section className="introduction">
      <div className="flex flex-col lg:flex-row sm:mt-44">
        <div className="lg:w-1/2 w-full p-10 lg:p-0 sm self-center lg:ml-[90px]">
          <p
            data-aos="fade-up"
            data-aos-duration="900"
            className="font-bold text-2xl md:text-3xl"
          >
            I’m Jan Sekne.
          </p>
          <p data-aos="fade-up" data-aos-duration="1400" className="text-lg">
            In the last few years I’ve worked for several companies and I’ve
            shown them my passion and willingness to learn whatever they threw
            at me. As a result, I can ensure you, that you will not be
            disappointed with my work.
          </p>
          <p
            data-aos="fade-up"
            data-aos-duration="1800"
            className="mt-7 text-lg"
          >
            In the past I have worked for several other companies, gaining experience and knowledge in a variety of areas. I am committed to continuously learning and pushing myself to new heights.
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="lg:w-1/2 w-full justify-center flex p-10 lg:p-0"
        >
          <div className="rounded-2xl relative shadow-xl w-96 h-auto justify-self-center">
            <div className="absolute -inset-1 -z-10 bg-gradient-to-br from-red-400 to-teal-600 rounded-2xl blur opacity-100 transition duration-1000"></div>
            <img alt="jan sekne" className="rounded-2xl h-full w-full" src={guitar_profile} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Introduction;
