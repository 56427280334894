import React from "react";
import Hero from "../UI/Hero/hero";
import Introduction from "./introduction/introduction";
import Skills from "./skills/skills";
import Qualities from "./qualities/qualities";
import Certificates from "./certificates/Certificates";


function AboutMe() {
  return (
    <>
      <Introduction />
      <Qualities />
      <Skills />
      <Certificates />
      <Hero />
    </>
  );
}

export default AboutMe;