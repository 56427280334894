import React from "react";
import "./button.css";

function Button({ link, content, img }) {
  return (
    <a href={link}>
      <div className="button-work">
        <button>{content}</button>
        {img != null && <img alt="button" src={img} />}
      </div>
    </a>
  );
}

export default Button;
