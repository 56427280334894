import React from "react";
import "./qualities.css";
import qua_img from "../../../assets/images/quality.webp";
import cre_img from "../../../assets/images/cre.webp";
import comm_img from "../../../assets/images/comm.webp";

function Qualities() {
  return (
    <section className="qualities mt-[30vh] mr-[10vw] mb-[25vh] ml-[10vw] lg:ml-[90px]">
      <div className="quality-box">
        <p data-aos="fade-right" data-aos-duration="900" className="font-bold text-2xl md:text-3xl">Regardles of your needs, you will always get:</p>
        <div className="list-of-qualities">
          <div
            className="qualities-div"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
            data-aos-offset="0"
          >
            <img alt="quality" src={qua_img} />
            <p className="quality-title">Quality</p>
            <p>
              I don’t want to put my name on something I’m not proud of. I
              always do my best to suit your needs.
            </p>
          </div>
          <div
            className="qualities-div"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-offset="0"
          >
            <img alt="creativity" src={cre_img} />
            <p className="quality-title">Creativity</p>
            <p>
              When faced with the problems I tend to find as much solutions as
              possible. I like to challenge myself outside of my comfort zone to
              create something I’ve never done before.
            </p>
          </div>
          <div
            className="qualities-div"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0"
          >
            <img alt="comment" src={comm_img} />
            <p className="quality-title">Communication</p>
            <p>
              I’m very communicative, happy and a funny person. I avoid negativity
              and don’t mind to accept my mistakes - so I can learn from them.
              As they say - Laughter is the best medicine
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Qualities;
