import React from "react";
import "./banner.css";


function Banner() {
  return (
    <section className="contact-banner">
      <p data-aos="fade-up" data-aos-duration="900">Interested in my work?</p>
      <p data-aos="fade-up" data-aos-duration="1400">If you’re looking for a full stack web and mobile developer that likes to expand knowledge every day, let’s talk.</p>
      <div className="email-button" data-aos="fade-up" data-aos-duration="1800">sekne50@gmail.com</div>
    </section>
  );
}

export default Banner;