import React, { useState } from "react";
import "./faq.css";

function Faq() {
  const [answer1Visible, setAnswer1Visible] = useState(false);
  const [answer2Visible, setAnswer2Visible] = useState(false);
  const [answer3Visible, setAnswer3Visible] = useState(false);

  function changeVisibiliy(answer) {
    switch (answer) {
      case "1":
        setAnswer1Visible(!answer1Visible);
        break;
      case "2":
        setAnswer2Visible(!answer2Visible);
        break;
      case "3":
        setAnswer3Visible(!answer3Visible);
        break;
      default:
        break;
    }
  }

  return (
    <section className="faq">
      <div className="faq-content">
        <div className="question-list">
          <div className="question">
            <div
              className={
                answer1Visible ? "circle-plus opened" : "circle-plus closed"
              }
            >
              <div className="circle" onClick={() => changeVisibiliy("1")}>
                <div className="horizontal"></div>
                <div className="vertical"></div>
              </div>
            </div>
            <p style={{ fontWeight: answer1Visible ? "bold" : "unset" }}>
              What timezone are you in?
            </p>
          </div>
          <p
            className="answer"
            style={{
              display: answer1Visible ? "block" : "none",
              marginBottom: "45px",
            }}
          >
            Currently I'm in timezone: CEST GMT+1 Ljubljana
          </p>
          <hr />
        </div>
        <div className="question-list">
          <div className="question">
            <div
              className={
                answer2Visible ? "circle-plus opened" : "circle-plus closed"
              }
            >
              <div className="circle" onClick={() => changeVisibiliy("2")}>
                <div className="horizontal"></div>
                <div className="vertical"></div>
              </div>
            </div>
            <p style={{ fontWeight: answer2Visible ? "bold" : "unset" }}>
              How old are you and where are you from?
            </p>
          </div>
          <p
            className="answer"
            style={{
              display: answer2Visible ? "block" : "none",
              marginBottom: "45px",
            }}
          >
            I'm 24 years old and I'm from Slovenia, Kranj.
          </p>
          <hr />
        </div>
        <div className="question-list">
          <div className="question">
            <div
              className={
                answer3Visible ? "circle-plus opened" : "circle-plus closed"
              }
            >
              <div className="circle" onClick={() => changeVisibiliy("3")}>
                <div className="horizontal"></div>
                <div className="vertical"></div>
              </div>
            </div>
            <p style={{ fontWeight: answer3Visible ? "bold" : "unset" }}>
              What's your working status?
            </p>
          </div>
          <p
            className="answer"
            style={{
              display: answer3Visible ? "block" : "none",
              marginBottom: "45px",
            }}
          >
            Currently, I'm a student but I'm always open in search for a
            great team and projects.
          </p>
          <hr />
        </div>
      </div>
    </section>
  );
}

export default Faq;
