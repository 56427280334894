import React from 'react';
import { Link } from 'react-router-dom';
import shrug from "../../assets/images/shrug.webp";
import "./PageNotFound.css";

function PageNotFound() {
  return (
    <section className='pagenotfound'>
      <div className='content-pnf'>
         <p>Page that you were looking for way not found <img alt="shrug" src={shrug}/></p>
         <p>Please go back to the <Link to="/">Home page</Link></p>
      </div>
    </section>
  )
}

export default PageNotFound;