import { gql, GraphQLClient } from "graphql-request";
import React, { useEffect } from "react";
import { useState } from "react";
import Certificate from "./Certificate";

function Certificates() {
  const [cert, setCert] = useState([]);
  const graphcms = new GraphQLClient(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cleh8lpll1rjr01t65bgydx34/master"
  );
  const QUERY = gql`
    {
      certificates {
        title
        slug
        picture {
          id
          url
        }
      }
    }
  `;
  useEffect(() => {
    graphcms.request(QUERY).then((r) => {
      return setCert(r.certificates);
    });
  }, []);
  return (
    <section className="skills">
      <p className="font-bold text-2xl md:text-3xl mb-10">Certificates</p>
      <div className="grid gap-5 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {cert.map((c, i) => {
          return (
            <Certificate
              key={i}
              title={c.title}
              url={c.picture.url}
              link={c.slug}
            />
          );
        })}
      </div>
    </section>
  );
}

export default Certificates;
