import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import emailjs from "@emailjs/browser";
import "./form.css";

function Form() {
  const form = useRef();
  const formInitialDetails = {
    yourName: "",
    email: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Let's do this!");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    emailjs
      .sendForm("service_43kmjgq", "tmpl_1", form.current, "cWDXGDOnpRm99Xdu-")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setTimeout(() => {
      setButtonText("Successfully sent");
    }, 1000);
    setTimeout(() => {
      setButtonText("Send");
    }, 1000);
    setFormDetails(formInitialDetails);
  };

  return (
    <section className="contact" id="contact">
      <Container>
        <Row className="align-items-center box">
          <Col size={12} md={6}>
            <TrackVisibility>
              <div className="form-text">
                <p>Got a project in mind?</p>
                <p>
                  Use the form to get in touch with me and I’ll get back toyou
                  as soon as I can.
                </p>
                <p>
                  Alternatively, get in touch using social media or the
                  information below.
                </p>
                <p>sekne50@gmail.com</p>
              </div>
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              <div>
                <form
                  ref={form}
                  onSubmit={handleSubmit}
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  data-netlify-recaptcha="true"
                >
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <p className="hidden">
                        <label>
                          Don’t fill this out if you’re human:{" "}
                          <input name="bot-field" />
                        </label>
                      </p>
                      <p className="labels">Your Name</p>
                      <input
                        type="text"
                        name="your_name"
                        value={formDetails.firstName}
                        placeholder="Your Name"
                        onChange={(e) =>
                          onFormUpdate("yourName", e.target.value)
                        }
                      />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <p className="labels">Your Email address</p>
                      <input
                        type="email"
                        name="email"
                        value={formDetails.email}
                        placeholder="Email Address"
                        onChange={(e) => onFormUpdate("email", e.target.value)}
                      />
                    </Col>
                    <Col size={6} className="mt-22 px-1">
                      <p className="labels">Content</p>
                      <textarea
                        rows="6"
                        value={formDetails.message}
                        name="message"
                        placeholder="Message"
                        onChange={(e) =>
                          onFormUpdate("message", e.target.value)
                        }
                      ></textarea>
                      <button type="submit">
                        <span>{buttonText}</span>
                      </button>
                    </Col>
                    {status.message && (
                      <Col>
                        <p
                          className={
                            status.success === false ? "danger" : "success"
                          }
                        >
                          {status.message}
                        </p>
                      </Col>
                    )}
                  </Row>
                </form>
              </div>
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Form;
